/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');


.App {
  margin: 0 auto;
  color: black;
}




/* https://www.youtube.com/watch?v=LQcgGcLJhvg */
